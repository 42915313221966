import React, { useState, useEffect } from "react";

//Components
import * as styled from "./grid.styles";
import Node from "../node/node.jsx";
import Sidebar from "../Sidebar/Sidebar";
import Select from "react-select";
import { SelectContainer } from "../Sidebar/sidebar.styles";
import Modal from "../Modal/modal";

//Algorithms
import {
    astar,
    getNodesInShortestPathOrderAstar,
} from "../../algorithms/astar";
import {
    dijkstra,
    getNodesInShortestPathOrder,
} from "../../algorithms/djikstra";
import { bfs } from "../../algorithms/bfirst";
import { dfs } from "../../algorithms/dfirst";
import { bidirectionalSearch } from "../../algorithms/bidirectional";
import { generateMaze } from "../../utils/makeMaze";

// Icons
import { GoX } from "react-icons/go";
import { FaSquare, FaPlay, FaWaveSquare } from "react-icons/fa";
import { IoMdHelp } from "react-icons/io";

function initGrid(rowSize, colSize) {
    const grid = [];
    if (rowSize !== undefined && colSize !== undefined) {
        for (let row = 0; row < rowSize; row++) {
            const currRow = [];
            for (let col = 0; col < colSize; col++) {
                currRow.push(initNode(col, row));
            }
            grid.push(currRow);
        }
    } else {
        let element = document.getElementById("grid");
        let positionInfo = element.getBoundingClientRect();
        let height = Math.round(positionInfo.height);
        let width = Math.round(positionInfo.width);
        // Calculates the amount of nodes that can fit into the grid
        // on reset or on resize
        // Check nodes.styles.js line 41 to 44
        // to get an idea of the size of each individual node
        for (let row = 0; row < height / 90; row++) {
            const currRow = [];
            for (let col = 0; col < width / 90; col++) {
                currRow.push(initNode(col, row));
            }
            grid.push(currRow);
        }
    }
    return grid;
}

const initNode = (col, row) => {
    return {
        col,
        row,
        isStart: false,
        isEnd: false,
        distance: Infinity,
        isVisited: false,
        isVisitedVis: false,
        isWall: false,
        isPath: false,
        isPathVis: false,
        previousNode: null,
        cost: {
            F: Infinity,
            G: Infinity,
            H: Infinity,
        },
    };
};

export default function Grid() {
    const [grid, setGrid] = useState([]);
    const [isPressed, setIsPressed] = useState(false);
    const [startNode, setStartNode] = useState({});
    const [endNode, setEndNode] = useState({});
    const [updatingStart, setUpdatingStart] = useState(false);
    const [updatingEnd, setUpdatingEnd] = useState(false);
    const [selectedAlgo, setSelectedAlgo] = useState({
        value: 0,
        label: "Djikstra's",
    });
    const [isModalOpen, setIsModalOpen] = useState(true);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            getGridSize();
        });
        resizeObserver.observe(document.getElementById("grid"));
    }, []);

    // Initial Grid size
    function getGridSize() {
        if (window.innerWidth <= 1001) {
            const rowSize = Math.floor(
                document.getElementById("grid").clientHeight / 40
            );
            const colSize = Math.floor(
                document.getElementById("grid").clientWidth / 40
            );
            setGrid(() => initGrid(rowSize, colSize));
        } else {
            const rowSize = Math.floor(
                document.getElementById("grid").clientHeight / 80
            );
            const colSize = Math.floor(
                document.getElementById("grid").clientWidth / 80
            );
            setGrid(() => initGrid(rowSize, colSize));
        }
    }

    function makeMaze() {
        const newGrid = generateMaze(grid);
        return setGrid([...newGrid]);
    }

    function handleMouseDown(row, col) {
        if (updatingEnd === true || updatingStart === true) {
        } else {
            toggleWall(row, col);
            setIsPressed(true);
        }
    }

    function handleMouseEnter(row, col) {
        if (updatingEnd === false || updatingStart === false) {
            if (isPressed === true) {
                toggleWall(row, col);
            }
        } else {
        }
    }
    function handleMouseUp() {
        setIsPressed(false);
    }

    function toggleWall(row, col) {
        const currentNode = grid[row][col];
        if (
            !currentNode.isPathVis &&
            !currentNode.isEnd &&
            !currentNode.isStart &&
            !currentNode.isVisitedVis
        ) {
            currentNode.isWall = !currentNode.isWall;
            setGrid([...grid]);
        }
    }

    function startNodeUpdateEventLoop() {
        setUpdatingStart(true);
    }

    function updateStart(row, col) {
        const currentNode = grid[row][col];
        if (
            Object.keys(startNode).length === 0 &&
            startNode.construtor === Object
        ) {
            console.log(startNode);
            setStartNode(currentNode);
        } else {
            startNode.isStart = false;
            setStartNode(currentNode);
            currentNode.isStart = !currentNode.isStart;
        }
        setUpdatingStart(false);
        setGrid([...grid]);
    }

    function endNodeUpdateEventLoop() {
        setUpdatingEnd(true);
    }

    function updateEnd(row, col) {
        const currentNode = grid[row][col];
        if (
            Object.keys(endNode).length === 0 &&
            endNode.construtor === Object
        ) {
            console.log(endNode);
            setEndNode(currentNode);
        } else {
            endNode.isEnd = false;
            setEndNode(currentNode);
            currentNode.isEnd = !currentNode.isEnd;
        }
        setUpdatingEnd(false);
        setGrid([...grid]);
    }

    function onNodeClick(row, col, isStart, isEnd) {
        if (updatingStart === true) {
            updateStart(row, col);
        }
        if (updatingEnd === true) {
            updateEnd(row, col, isStart, isEnd);
        }
        return {
            row,
            col,
            isStart,
            isEnd,
        };
    }

    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    async function animateShortestPath(shortestPath, grid) {
        for (var i = 0; i < shortestPath.length; i++) {
            let node = shortestPath[i];
            node.isPathVis = true;
            setGrid([...grid]);
            await timer(5);
        }
    }

    async function animateVisitedNodes(
        visitedNodesInOrder,
        grid,
        shortestPath,
        animateShortestPath
    ) {
        if (visitedNodesInOrder !== undefined && visitedNodesInOrder !== null) {
            for (var i in visitedNodesInOrder[0]) {
                const node = visitedNodesInOrder[0][i];
                if (node.isVisited) {
                    node.isVisitedVis = true;
                }
                setGrid([...grid]);
                await timer(10);
            }
            animateShortestPath(shortestPath, grid);
        } else {
            window.alert("No viable path found!");
            return;
        }
    }

    function visDjikstra(grid) {
        const startingNode = startNode;
        const endingNode = endNode;
        const visitedNodesInOrder = dijkstra(grid, startingNode, endingNode);
        const shortestPath = getNodesInShortestPathOrder(endingNode);
        animateVisitedNodes(
            visitedNodesInOrder,
            grid,
            shortestPath,
            animateShortestPath
        );
    }

    function visBfs(grid) {
        const startingNode = startNode;
        const endingNode = endNode;
        if (startingNode !== undefined && endingNode !== undefined) {
            const visitedNodesInOrder = bfs(grid, startingNode, endingNode);
            const shortestPath = getNodesInShortestPathOrderAstar(endingNode);
            animateVisitedNodes(
                visitedNodesInOrder,
                grid,
                shortestPath,
                animateShortestPath
            );
        } else {
            window.alert("Check start/end nodes");
        }
    }

    function visDfs(grid) {
        const startingNode = startNode;
        const endingNode = endNode;
        if (startingNode !== undefined && endingNode !== undefined) {
            const visitedNodesInOrder = dfs(grid, startingNode, endingNode);
            const shortestPath = getNodesInShortestPathOrderAstar(endingNode);
            animateVisitedNodes(
                visitedNodesInOrder,
                grid,
                shortestPath,
                animateShortestPath
            );
        } else {
            window.alert("Check start/end nodes");
        }
    }

    function visAstar(grid) {
        const startingNode = startNode;
        const endingNode = endNode;
        const visitedNodesInOrder = astar(grid, startingNode, endingNode);
        const shortestPath = getNodesInShortestPathOrderAstar(endingNode);
        animateVisitedNodes(
            visitedNodesInOrder,
            grid,
            shortestPath,
            animateShortestPath
        );
    }

    async function animateVisitedNodesSource(
        source_visited,
        grid,
        sPathNodes,
        animateShortestPath
    ) {
        if (source_visited !== undefined && source_visited !== null) {
            for (var i in source_visited[0]) {
                const node = source_visited[0][i];
                if (node.isVisited) {
                    node.isVisitedVis = true;
                }
                setGrid([...grid]);
                await timer(10);
            }
            animateShortestPath(sPathNodes, grid);
        } else {
            window.alert("No viable path found!");
            return;
        }
    }

    async function animateVisitedNodesDest(
        dest_visited,
        grid,
        dPathNodes,
        animateShortestPath
    ) {
        if (dest_visited !== undefined && dest_visited !== null) {
            for (var i in dest_visited[0]) {
                const node = dest_visited[0][i];
                if (node.isVisited) {
                    node.isVisitedVis = true;
                }
                setGrid([...grid]);
                await timer(10);
            }
            animateShortestPath(dPathNodes, grid);
        } else {
            window.alert("No viable path found!");
            return;
        }
    }

    function visBiDir(grid) {
        const startingNode = startNode;
        const endingNode = endNode;
        const [source_visited, dest_visited, sPathNodes, dPathNodes] =
            bidirectionalSearch(grid, startingNode, endingNode);
        animateVisitedNodesSource(
            source_visited,
            grid,
            sPathNodes,
            animateShortestPath
        );
        animateVisitedNodesDest(
            dest_visited,
            grid,
            dPathNodes,
            animateShortestPath
        );
    }

    function clearGrid() {
        setGrid(initGrid());
        setStartNode({});
        setEndNode({});
        setIsPressed(false);
    }

    const selectStyle = {
        control: (styles) => ({
            ...styles,
            border: "2px solid white",
            fontWeight: "600",
        }),
        option: (styles) => {
            return {
                ...styles,
                fontWeight: "500",
            };
        },
    };

    const options = [
        { value: 0, label: "Djikstra's" },
        { value: 1, label: "A* Search" },
        { value: 2, label: "Bidirectional" },
        { value: 3, label: "Breadth first search" },
        { value: 4, label: "Depth first search" },
    ];

    function startAnim(selectedAlgo) {
        switch (selectedAlgo.value) {
            case 0:
                visDjikstra(grid);
                break;
            case 1:
                visAstar(grid);
                break;
            case 2:
                visBiDir(grid);
                break;
            case 3:
                visBfs(grid);
                break;
            case 4:
                visDfs(grid);
                break;
            default:
                visDjikstra(grid);
                break;
        }
    }

    return (
        <>
            <Sidebar>
                <SelectContainer>
                    <Select
                        isDisabled={updatingStart || updatingEnd ? true : false}
                        onChange={setSelectedAlgo}
                        defaultValue={{ value: 0, label: "Djikstra's" }}
                        options={options}
                        styles={selectStyle}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 8,
                            colors: {
                                ...theme.colors,
                                primary25: "#4a4a4a",
                                primary50: "#4a4a4a",
                                neutral30: "#fff",
                                primary: "#fff",
                                neutral80: "#fff",
                                neutral190: "#fff",
                                neutral0: "#171717",
                            },
                        })}
                    />
                </SelectContainer>
                <styled.Button
                    onClick={() => {
                        if (
                            Object.keys(startNode).length === 0 ||
                            Object.keys(endNode).length === 0
                        ) {
                            window.alert("Verify start and end nodes!");
                        } else {
                            startAnim(selectedAlgo);
                        }
                    }}
                    disabled={updatingStart || updatingEnd ? true : false}
                >
                    <FaPlay
                        style={{ fontSize: "0.80em", marginBottom: "2px" }}
                    />
                    Begin
                </styled.Button>
                <styled.Button
                    onClick={() => clearGrid()}
                    disabled={updatingStart || updatingEnd ? true : false}
                >
                    <GoX style={{ fontSize: "1.05em" }} />
                    Reset
                </styled.Button>
                <styled.Button onClick={() => setIsModalOpen(true)}>
                    <IoMdHelp style={{ fontSize: "1.05em" }} />
                    Help
                </styled.Button>
                <styled.Button
                    onClick={() => startNodeUpdateEventLoop()}
                    disabled={updatingEnd ? true : false}
                >
                    <FaSquare
                        style={{
                            color: "#22e03e",
                            marginBottom: "2px",
                        }}
                    />
                    Initial
                </styled.Button>
                <styled.Button
                    onClick={() => endNodeUpdateEventLoop()}
                    disabled={updatingStart ? true : false}
                >
                    <FaSquare
                        style={{
                            marginBottom: "2px",
                            color: "#ff0000",
                        }}
                    />
                    Goal
                </styled.Button>
                <styled.Button
                    onClick={() => makeMaze(grid)}
                    disabled={updatingStart || updatingEnd ? true : false}
                >
                    <FaWaveSquare />
                    Maze
                </styled.Button>
            </Sidebar>
            <styled.gridContainer>
                <styled.grid id="grid" onMouseLeave={() => setIsPressed(false)}>
                    {grid.map((row, rowIndex) => {
                        return (
                            <styled.Row
                                key={rowIndex}
                                style={{ display: "flex" }}
                            >
                                {row.map((node, nodeIndex) => {
                                    const {
                                        col,
                                        row,
                                        isStart,
                                        isEnd,
                                        distance,
                                        isVisited,
                                        isWall,
                                        isPath,
                                        isPathVis,
                                        isVisitedVis,
                                        previousNode,
                                    } = node;
                                    return (
                                        <Node
                                            key={nodeIndex}
                                            col={col}
                                            row={row}
                                            isEnd={isEnd}
                                            isStart={isStart}
                                            isWall={isWall}
                                            isPath={isPath}
                                            isPathVis={isPathVis}
                                            isVisited={isVisited}
                                            distance={distance}
                                            isVisitedVis={isVisitedVis}
                                            previousNode={previousNode}
                                            isPressed={isPressed}
                                            onMouseClick={onNodeClick}
                                            onMouseDown={handleMouseDown}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseUp={handleMouseUp}
                                        />
                                    );
                                })}
                            </styled.Row>
                        );
                    })}
                </styled.grid>
                {isModalOpen && (
                    <Modal closeModal={() => setIsModalOpen(false)}>
                        <span>
                            The basic life cycle of the program is pretty simple
                        </span>
                        <h2>Algorithms and Nodes</h2>
                        <ul>
                            <li>Select an algorithm from the dropdown</li>
                            <li>
                                Press the <b>Initial</b> button and select any
                                node as the starting node
                            </li>
                            <li>
                                Press the <b>Goal</b> button and select any node
                                as the goal node
                            </li>
                        </ul>
                        <h2>Maze</h2>
                        <span>Two ways to make a maze</span>
                        <ul>
                            <li>
                                Either you can click the maze button and auto
                                generate it using prim's alogrithm
                            </li>
                            <li>
                                Or you can hold your left click down and drag
                                around to draw walls, individual nodes can also
                                be clicked on
                            </li>
                        </ul>
                        <h2>Misc</h2>
                        <span>
                            Resize the grid to a desirable size from the bottom
                            right of the grid border
                        </span>
                        <br />
                        <span>
                            I took a brief look at making the resize handler
                            more visible but all of the methods seem rather
                            hacky
                        </span>
                        <br />
                        <span>
                            The source code can be found at:
                            <br />
                            <a href="https://git.arjun.lol/Pathfinder/file/README.md.html">
                                https://git.arjun.lol/Pathfinder/file/README.md.html
                            </a>
                        </span>
                        <br />
                        <span>
                            Report issues at: <br />
                            <a href="mailto:contact@arjunchoudhary.com">
                                contact@arjunchoudhary.com
                            </a>
                        </span>
                        <br />
                        <span>
                            This is currently hosted on Cloudflare and by
                            extension is beholden to it's{" "}
                            <a href="https://www.cloudflare.com/website-terms/">
                                Terms of use
                            </a>
                        </span>
                    </Modal>
                )}
            </styled.gridContainer>
        </>
    );
}
