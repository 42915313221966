import Grid from "../grid/grid";
import { StyledPathFindingVis } from "./pathfindingvis.styles";

export default function PathFindingVis() {
	return (
		<StyledPathFindingVis>
			<Grid />
		</StyledPathFindingVis>
	);
}
