import styled from "styled-components";

export const sidebar = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 15%;
    border-right: 0px solid white;
    @media (max-width: 1000px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 95vw;
        flex-wrap: wrap
    }
`;

export const SelectContainer = styled.div `
  width: 100%;
  @media (max-width: 1000px) {
      width: 95vw;
  }
`;