import styled from "styled-components";

export const StyledPathFindingVis = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: flex-start;
    overflow: hidden;
    @media (max-width: 1000px) {
        font-size: 15px;
        padding: 5px 0px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }
`;
