import styled from "styled-components";

export const Row = styled.div`
    display: flex;
`;

export const Button = styled.button`
    width: auto;
    min-width: 90px;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    background-color: transparent;
    font-size: 1em;
    border: 2px solid white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    vertical-align: middle;
    margin: 10px;
    &:hover {
        color: black;
        background-color: white;
    }
    transition: all 0.25s linear;
    &:disabled {
        color: grey;
        opacity: 1;
        background-color: #f2f2f2;
        border-color: black;
    }
    @media (max-width: 1000px) {
        font-size: 0.95em;
        border: 1px solid white;
    }
`;

export const gridContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 85%;
    max-height: 100%;
    @media (max-width: 1000px) {
        width: 99vw;
        max-width: 99vw;
        height: 99vh;
        max-height: 99vh;
    }
`;

export const grid = styled.div`
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid white;
    border-radius: 17px;
    resize: both;
    overflow: hidden;
    min-height: 25%;
    min-width: 25%;
    max-width: 95%;
    max-height: 95%;
    @media (max-width: 1000px) {
        border: 1px solid white;
    }
`;
